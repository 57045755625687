import React from 'react'

export default function ExpandedIcon() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="16.838" height="8.593" viewBox="0 0 16.838 8.593">
            <g id="Group_478" data-name="Group 478" transform="translate(0.707 0.707)">
                <line
                    id="Line_322"
                    data-name="Line 322"
                    x1="7.712"
                    y2="7.18"
                    transform="translate(0 0)"
                    fill="none"
                    stroke="#42526e"
                    strokeLinecap="round"
                    strokeWidth="1"
                />
                <line
                    id="Line_323"
                    data-name="Line 323"
                    x2="7.712"
                    y2="7.18"
                    transform="translate(7.712 0)"
                    fill="none"
                    stroke="#42526e"
                    strokeLinecap="round"
                    strokeWidth="1"
                />
            </g>
        </svg>
    )
}
