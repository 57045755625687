import { animateScroll } from 'react-scroll'

export const handleScrollToTop = () => {
    animateScroll.scrollToTop({
        duration: 100,
    })
}

export const scrollTo = (position: number = 0) => {
    animateScroll.scrollTo(position, {
        duration: 100,
    })
}
