import React from 'react'
import Helmet from 'react-helmet'
import useStore from '@/zustand/sotre'

export default () => {
    const { storeState } = useStore()
    return (
        <Helmet>
            {/* https://material-ui.com/getting-started/installation/#installation */}
            <link
                href={
                    'https://fonts.googleapis.com/css?family=Material+Icons' +
                    '|Material+Icons+Outlined' +
                    '|Material+Icons+Round' +
                    '|Material+Icons+Sharp' +
                    '|Material+Icons+Two+Tone'
                }
                rel="stylesheet"
            />
            <link rel="preconnect" href="https://fonts.googleapis.com" />
            <link rel="preconnect" href="https://fonts.gstatic.com" crossOrigin="anonymous" />
            <link href="https://fonts.googleapis.com/css2?family=Noto+Sans+JP&display=swap" rel="stylesheet" />
            <link
                rel="icon"
                type="image/x-icon"
                href={storeState.organization?.id === 98
                    ? '/assets/logo/favicon_westhd.ico'
                    : '/assets/logo/favicon.ico'
                }
            />
        </Helmet>
    )
}
